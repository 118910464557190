import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withFetch, withJsonpSupport } from '@angular/common/http';
import { Route, provideRouter, withComponentInputBinding, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { newsAndUpdatesResolver, newsContentResolver } from './news-and-updates/news-and-updates.resolver';
import { homeNewsAndUpdatesResolver } from './home/home.resolver';

function appInitializerFactory(translate: TranslateService) {
  return () => new Promise<void>((resolve) => {
    translate.addLangs(['en', 'fr']); // Add your languages here

    resolve();
  });
}

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const routes: Route[] = [
  {
    path: ':lang',
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
        resolve: {
          news: homeNewsAndUpdatesResolver,
        },
      },
      {
        path: 'investors',
        loadComponent: () => import('./investors/investors.component').then(m => m.InvestorsComponent),
      },
      {
        path: 'privacy-policy',
        loadComponent: () => import('./privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
      },
      {
        path: 'news-and-updates',
        loadComponent: () => import('./news-and-updates/news-and-updates.component').then(m => m.NewsAndUpdatesComponent),
        resolve: {
          contents: newsAndUpdatesResolver,
        },
      },
      {
        path: 'news-and-updates/:slug',
        loadComponent: () => import('./news-and-updates/news-view/news-view.component').then(m => m.NewsViewComponent),
        runGuardsAndResolvers: 'always',
        resolve: {
          content: newsContentResolver,
        },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'en/home',
    pathMatch: 'full',
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    provideRouter(
      routes,
      withComponentInputBinding(),
      withViewTransitions(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      }),
    ),
    provideHttpClient(
      withJsonpSupport(),
      withFetch(),
    ),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }),
    ]),
    provideClientHydration(withHttpTransferCacheOptions({
      includePostRequests: true
    })),
  ],
};
