<div class="container nav-bar">
  <nav class="nav-bar__container">
    <a class="nav-bar__logo-link" [routerLink]="['/', currentLang$(), 'home']">
      <img
        class="nav-bar__logo"
        src="../../../../assets/images/care-hive-logo.svg"
        alt="CareHive"
        width="231"
        height="47" />
    </a>

    <div class="nav-bar__link-container">
      <button
        class="btn btn--hamburger nav-bar__hamburger"
        (click)="toggleMobileMenu()">

        Menu
      </button>

      <ul class="nav-bar__links" [class.nav-bar__links--active]="mobileMenuOpen$()">
        <li>
          <a
            class="nav-bar__link"
            [routerLink]="['/', currentLang$(), 'home']"
            routerLinkActive="nav-bar__link--active"
            (click)="toggleMobileMenu(false)">

            {{ 'HOME' | translate }}
          </a>
        </li>

        <li>
          <a
            class="nav-bar__link"
            [routerLink]="['/', currentLang$(), 'investors']"
            routerLinkActive="nav-bar__link--active"
            (click)="toggleMobileMenu(false)">

            {{ 'INVESTORS' | translate }}
          </a>
        </li>

        <li>
          <a
            class="nav-bar__link"
            [routerLink]="['/', currentLang$(), 'news-and-updates']"
            routerLinkActive="nav-bar__link--active"
            (click)="toggleMobileMenu(false)">

            {{ 'NEWS_AND_UPDATES' | translate }}
          </a>
        </li>

        <li class="nav-bar__join">
          <p class="btn btn--primary nav-bar__join-btn">
            {{ 'JOIN_THE_WAITLIST' | translate }}
          </p>

          <ul class="nav-bar__join-sub">
            <li>
              <a
                class="nav-bar__link nav-bar__link-sub"
                href="{{ 'IM_A_MEDICAL_PROFESSIONAL_LINK' | translate }}"
                target="_blank"
                rel="nofollow noopener"
                (click)="facebookPixel()">

                {{ 'IM_A_MEDICAL_PROFESSIONAL' | translate }}
              </a>
            </li>

            <li>
              <a
                class="nav-bar__link nav-bar__link-sub"
                href="{{ 'IM_A_PATIENT_LINK' | translate }}"
                target="_blank"
                rel="nofollow noopener"
                (click)="facebookPixel()">

                {{ 'IM_A_PATIENT' | translate }}
              </a>
            </li>
          </ul>

          <hr class="hr hr--horizontal nav-bar__join-hr">

          <p class="nav-bar__join-label">
            {{ 'JOIN_THE_WAITLIST' | translate }}:
          </p>

          <ul class="nav-bar__join-sub--mobile">
            <li>
              <a
                class="btn btn--primary"
                href="{{ 'IM_A_MEDICAL_PROFESSIONAL_LINK' | translate }}"
                target="_blank"
                rel="nofollow noopener"
                (click)="facebookPixel()">

                {{ 'IM_A_MEDICAL_PROFESSIONAL' | translate }}
              </a>
            </li>

            <li>
              <a
                class="btn btn--primary"
                href="{{ 'IM_A_PATIENT_LINK' | translate }}"
                target="_blank"
                rel="nofollow noopener"
                (click)="facebookPixel()">

                {{ 'IM_A_PATIENT' | translate }}
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <hr class="hr hr--vertical nav-bar__hr">

      <a
        class="btn btn--link nav-bar__lang-link"
        [routerLink]="getCurrentRoute()">

        {{ langLinkLabel$() }}
      </a>
    </div>
  </nav>
</div>
