import { Injectable } from '@angular/core';
import * as contentful from 'contentful';
import { EntriesQueries, EntrySkeletonType, ChainModifiers, EntryCollection, LocaleCode } from 'contentful';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private client = contentful.createClient({
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.accessToken,
  });

  getEntriesWithAllLocales(query?: EntriesQueries<EntrySkeletonType, ChainModifiers>): Promise<EntryCollection<EntrySkeletonType, ChainModifiers, LocaleCode>> {
    return this.client.withAllLocales.getEntries(query);
  }

  getEntries(query?: EntriesQueries<EntrySkeletonType, ChainModifiers>): Promise<EntryCollection<any, undefined, string>> {
    return this.client.getEntries(query);
  }
}
