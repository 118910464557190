import { Component, PLATFORM_ID, inject, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
  ],
})
export class NavBarComponent {
  protected readonly _translate = inject(TranslateService);
  protected readonly _router = inject(Router);
  protected readonly PLATFORM_ID = inject(PLATFORM_ID);

  currentLang$ = signal(this._translate.currentLang);
  langLinkLabel$ = signal(this._translate.currentLang === 'en' ? 'FR' : 'EN');
  mobileMenuOpen$ = signal(false);

  ngOnInit() {
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langLinkLabel$.set(event.lang === 'en' ? 'FR' : 'EN');
      this.currentLang$.set(event.lang);
    });
  }

  getCurrentRoute(): string[] {
    const currentUrl = this._router.url;
    const urlWithoutLang = currentUrl.slice(4);
    let routerLink = ['/', this.currentLang$() === 'en' ? 'fr' : 'en'];

    if (urlWithoutLang !== '') {
      routerLink = routerLink.concat(urlWithoutLang.split('/'));
    }

    return routerLink;
  }

  facebookPixel() {
    if (isPlatformBrowser(this.PLATFORM_ID) && (window as any).fbq) {
      (window as any).fbq('track', 'Lead');
    }
  }

  toggleMobileMenu(state?: boolean) {
    this.mobileMenuOpen$.set(state !== undefined ? state : !this.mobileMenuOpen$());
  }
}
