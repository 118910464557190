import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { inject } from '@angular/core'
import { ContentfulService } from '../core/services/contentful/contentful.service';

export const newsAndUpdatesResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(ContentfulService).getEntriesWithAllLocales({
    content_type: 'multiLanguageBlogPage',
    order: ['-sys.createdAt'],
    select: [
      'fields.slug',
      'fields.title',
      'fields.excerpt',
      'fields.coverImage',
      'sys.createdAt',
    ],
  });
}

export const newsContentResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(ContentfulService).getEntriesWithAllLocales({
    content_type: 'multiLanguageBlogPage',
    'fields.slug': route.params['slug'],
    include: 3,
    select: [
      'fields.slug',
      'fields.title',
      'fields.excerpt',
      'fields.body',
      'fields.coverImage',
      'fields.seoTitle',
      'fields.seoDescription',
      'fields.seoKeywords',
      'fields.seoImage',
      'sys.createdAt',
    ],
  });
}
