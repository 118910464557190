<app-nav-bar />

<router-outlet />

<footer class="footer">
  <div class="container footer__container">
    <div>
      <a [routerLink]="['/', currentLang$()]">
        <img
          class="footer__logo"
          src="../assets/images/care-hive-logo-white.svg"
          alt="CareHive"
          width="231"
          height="47" />
      </a>
    </div>

    <ul class="footer__links">
      <li class="footer__link-item">
        <a
          href="mailto:info@carehive.ca"
          class="footer__link">

          {{ 'CONTACT_US' | translate }}
        </a>
      </li>

      <li class="footer__link-item">
        <a
          class="footer__link"
          [routerLink]="['/', currentLang$(), 'investors']">

          {{ 'INVESTORS' | translate }}
        </a>
      </li>

      <li class="footer__link-item">
        <a
          class="footer__link"
          [routerLink]="['/', currentLang$(), 'privacy-policy']">

          {{ 'PRIVACY_POLICY' | translate }}
        </a>
      </li>

      <li class="footer__link-item">
        <p class="footer__link">{{ 'FOLLOW_US' | translate }}</p>

        <a
          class="footer__social-link"
          href="https://www.linkedin.com/company/carehive-app"
          target="_blank"
          rel="nofollow noopener">

          <img
            src="../assets/images/linkedin.svg"
            width="36"
            height="36">
        </a>

        <a
          class="footer__social-link"
          href="{{ 'FACEBOOK_LINK' | translate }}"
          target="_blank"
          rel="nofollow noopener">

          <img
            src="../assets/images/facebook.svg"
            width="36"
            height="36">
        </a>
      </li>
    </ul>
  </div>
</footer>
