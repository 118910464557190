import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule, RouterOutlet, RoutesRecognized } from '@angular/router';
import { LanguageService } from './core/services/language/language.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { NavBarComponent } from './core/components/nav-bar/nav-bar.component';
import { SeoService } from './core/services/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    TranslateModule,
    NavBarComponent,
  ],
})
export class AppComponent implements OnInit {
  protected readonly _langService = inject(LanguageService);
  protected readonly _translateService = inject(TranslateService);
  protected readonly _route = inject(Router);
  protected readonly _seoService = inject(SeoService);
  protected readonly PLATFORM_ID = inject(PLATFORM_ID);

  currentLang$ = this._langService.currentLang$;

  ngOnInit(): void {
    if (isPlatformBrowser(this.PLATFORM_ID) && (window as any).fbq) {
      (window as any).fbq('init', '897428158733674');
      (window as any).fbq('track', 'PageView');
    }

    this._route.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        this._translateService.use(val.state.root.firstChild?.params['lang']);
      }

      if (val instanceof NavigationStart) {
        if (isPlatformBrowser(this.PLATFORM_ID)) {
          this._seoService.removeSeoTags();
        }
      }

      if (val instanceof NavigationEnd) {
        if (isPlatformBrowser(this.PLATFORM_ID) && (window as any).fbq) {
          (window as any).fbq('track', 'PageView');
        }
      }
    });
  }
}
